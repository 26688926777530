import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, ElementRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import * as fromUser from '@app/core/store/user';
import { User } from '@app/core/models';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'realtime-one-video',
  templateUrl: './realtime-one-video.component.html',
  styleUrls: ['./realtime-one-video.component.scss']
})
export class RealtimeOneVideoComponent<T> implements OnInit {
  currentUser$: Observable<User>
  obs: BehaviorSubject<any>;
  myWebSocket: SafeResourceUrl;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  @Input() dataSource: MatTableDataSource<T>;
  @Input() paginationPageSizes: Array<number>;
  @Input() styleClass: string;
  @Input() styleClassLarge: string;

  private _onDestroy = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private store: Store<fromUser.UserState>) {
    this.currentUser$ = store.select(fromUser.getCurrentUser);
  }

  async ngOnInit() {
    this.changeDetectorRef.detectChanges();
    this.obs = this.dataSource.connect();

    this.obs.getValue().map((value) => {
      this.loadLiveVideo(value);
    })
  }

  loadLiveVideo(value) {
    value.WebSocketSubject.subscribe(msg => {
      if (msg) {
        // // console.log(msg);
        let reader = new FileReader(),
          base64data;
        reader.readAsDataURL(msg.data);
        reader.onloadend = (e) => {
          let re = /data:application\/octet-stream;/gi;
          base64data = reader.result;
          base64data = base64data.replace(re, '');
          // // console.log("data=>", base64data);
          value.myWebSocket = this.sanitizer.bypassSecurityTrustUrl('data:image/gif;' + base64data);
        }
      }
    });
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '每頁顯示筆數';
  }

  ngOnDestroy() {


    // if (this.obs) {
    //   // console.log(this.obs)
    //   this.obs.getValue().map((value) => {
    //     // console.log(value)
    //     value['WebSocketSubject'].unsubscribe();
    //     value['WebSocketSubject'] = undefined;
    //   })

    // }

    // this._onDestroy.next();
    // this._onDestroy.complete();


  }


}

