import { environment } from "../../environments/environment";

// const baseUrl: string = environment.baseUrl;
const baseUrl: string = environment.baseUrl + 'api/';

export const config: any = {
  cctvIP: "210.68.122.68",
  cctvPort: "9000",

  loginUrl: baseUrl + "User/UserLogin", // 使用者登入資訊
  logoutUrl: baseUrl + "User/UserLogout", // 使用者登出

  updateUserUrl: baseUrl + "User/UpdateUser", // 更新使用者帳號設定
  getReviewerUrl: baseUrl + "User/GetReviewer", // 更新使用者帳號設定
  getTeamInfoUrl: baseUrl + "User/GetTeamInfo", // 取得單位使用者資訊
  getUserCaseUrl: baseUrl + "User/GetUserCase", // 取得使用者案件

  roleUrl: baseUrl + "UserRole/GetRoles", // 取得角色權限
  authUrl: baseUrl + "UserRole/GetMenuAuth", // 取得選單權限
  updRoleUrl: baseUrl + "UserRole/UpdateRole", // 更新角色權限
  updateUserRoleUrl: baseUrl + "UserRole/UpdateUserRole", // 設定使用者的角色權限群組
  crtRoleUrl: baseUrl + "UserRole/CreateRole", // 建立新角色權限群組
  delRoleUrl: baseUrl + "UserRole/DeleteRole", // 刪除角色權限群組

  // logoutUrl: baseUrl + "auth/logout",
  reLoadSessionUrl: baseUrl + "auth/reLoadSession",
  versionUrl: baseUrl + "auth/version",

  historyByCarUrl: baseUrl + "CCTV/API001", // 查詢車牌軌跡
  historyByCCTVUrl: baseUrl + "CCTV/API002", // 攝影機查車牌
  cctvInfoUrl: baseUrl + "CCTV/API003", // 攝影機資訊
  cctvImg: baseUrl + "CCTV/API006", // 車牌快照
  SnapShotUrl: baseUrl + "CCTV/GetSnapshot", // 攝影機快照
  ExportVideoUrl: baseUrl + "CCTV/ExportVideo", // 匯出歷史影像檔

  getMyFavoriteUrl: baseUrl + "CCTV/GetMyFavorite", // 取得我的最愛列表
  crtMyFavoriteUrl: baseUrl + "CCTV/CreateMyFavorite", // 加入我的最愛
  delMyFavoriteUrl: baseUrl + "CCTV/DeleteMyFavorite", // 移除我的最愛

  crtGantryAreaUrl: baseUrl + "GantryArea/CreateGantryArea", // 建立新區域分群
  updGantryAreaUrl: baseUrl + "GantryArea/UpdateGantryArea", // 更新區域分群

  bothAnalysisUrl: baseUrl + "CCTV/InterSectionAnalysis", // 綜合分析(未完成)
  intersectionAnalysisUrl: baseUrl + "CCTV/InterSectionAnalysis_Intersection", // 交集分析(未完成)
  unionAnalysisUrl: baseUrl + "CCTV/InterSectionAnalysis_Union", // 聯集分析(未完成)

  crtHVLogUrl: baseUrl + "Record/CreateHistoryVideoLog", // 新增歷史log使用紀錄
  getHVLogUrl: baseUrl + "Record/GetHistoryVideoLog", // 取得歷史log使用紀錄
  crtRVLogUrl: baseUrl + "Record/CreateRealtimeVideoLog", // 新增即時log使用紀錄
  getRVLogUrl: baseUrl + "Record/GetRealtimeVideoLog", // 取得即時log使用紀錄
  getCRLogUrl: baseUrl + "Record/GetCarplateRecognitionLog", // 取得辨識log使用紀錄
  getUserLogUrl: baseUrl + "Record/GetUserStatisticLog", // 取得使用者紀錄統計

  getBBUrl: baseUrl + "Billboard/GetBillboard", // 取得公告
  crtBBUrl: baseUrl + "Billboard/CreateBillboard", // 新增公告
  updBBUrl: baseUrl + "Billboard/UpdateBillboard", // 更新公告
  delBBUrl: baseUrl + "Billboard/DeleteBillboard", // 刪除公告
  delBBFileUrl: baseUrl + "Billboard/DeleteBillboardFile", // 刪除公告檔案

  GetDeptUrl: baseUrl + "SysCode/GetDept", // 單位
  GetAreaUrl: baseUrl + "SysCode/GetGantryArea", // 所屬區域
  GetAreaCCTVUrl: baseUrl + "SysCode/GetGantryAreaCCTV", // 所屬區域雙階層攝影機
  SysCodeUrl: baseUrl + "SysCode/GetSysCode", // 所屬區域

  creatHVUrl: baseUrl + "HistoryVideoApply/CreateHistoryVideoApply", // 新增調閱申請
  getHVAUrl: baseUrl + "HistoryVideoApply/GetHistoryVideoApply", // 取得調閱申請
  updateHVAUrl: baseUrl + "HistoryVideoApply/UpdateHistoryVideoApply", // 更新調閱申請

  getCheckUrl: baseUrl + "CCTVCheck/GetCCTVCheckList", // 每日稽核查詢
  getDailyCheckUrl: baseUrl + "CCTVCheck/GetCCTVDailyCheck", // 取得每日稽核紀錄
  crtDailyCheckUrl: baseUrl + "CCTVCheck/CreateCCTVDailyCheck", // 建立每日稽核紀錄
  getHistoryStatusUrl: baseUrl + "CCTVCheck/GetHistoryStatus", // 歷史妥善率
  getEquipmentStatusUrl: baseUrl + "CCTVCheck/GetEquipmentStatus", // 取得設備狀態監控資訊
  getHistorySnapshotUrl: baseUrl + "CCTVCheck/GetHistorySnapshot", // 取得歷史畫面稽核

  updMaintainCheckUrl: baseUrl + "BillingCheck/UpdateMaintainCheck ", // 更新保養檢查資料
  getMaintainCheckUrl: baseUrl + "BillingCheck/GetMaintainCheckList", // 取得保養檢查列表
  updMonthlyRentDeductUrl: baseUrl + "BillingCheck/UpdateMonthlyRentDeduct", // 更新其他月租費扣款
  getMonthlyRentDeductUrl: baseUrl + "BillingCheck/GetMonthlyRentDeductList", // 取得其他月租費扣款
  getMonthlyDeductReportUrl:
    baseUrl + "BillingCheck/GetMonthlyRentDeductReport", // 	取得歷史儲檔報表
  getMaintainReportUrl: baseUrl + "BillingCheck/GetMaintainReport", // 取得保養報表
  getRepairReportUrl: baseUrl + "BillingCheck/GetRepairReport", // 取得影像維修報表
  updRentDeductApplyUrl: baseUrl + "BillingCheck/UpdateRentDeductApply", // 其他罰款項目申請
  getChargeReportUrl: baseUrl + "BillingCheck/GetChargeReport", // 取得計罰與帳務明細報表
  getDisconnectedReportUrl:
    baseUrl + "BillingCheck/GetHistoryDisconnectedReport", // 取得計罰與帳務明細報表
  getRepairApply: baseUrl + "RepairApply/GetRepairApply", // 取得報修單申請列表(申請人查詢代入RepairStatus；維修人員查詢代入CheckStatus)
  crtRepairApply: baseUrl + "RepairApply/CreateRepairApply", // 建立新報修單申請
  updRepairApply: baseUrl + "RepairApply/UpdateRepairApply", // 修改報修單(修理回報)
};
