import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sa-scu-layout',
    templateUrl: './scu-layout.component.html',
    styles: [],
})
export class ScuLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
