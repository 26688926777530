import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SmartadminLayoutModule } from "./layout";

import { I18nModule } from "./i18n/i18n.module";
import { UserModule } from "./user/user.module";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import { VoiceControlModule } from "./voice-control/voice-control.module";

import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";

import { UtilsModule } from "./utils/utils.module";
import { PipesModule } from "./pipes/pipes.module";
import { ChatModule } from "./chat/chat.module";
import { StatsModule } from "./stats/stats.module";
import { InlineGraphsModule } from "./graphs/inline/inline-graphs.module";
import { SmartadminFormsLiteModule } from "./forms/smartadmin-forms-lite.module";
import { SmartProgressbarModule } from "./ui/smart-progressbar/smart-progressbar.module";
import { CalendarComponentsModule } from "@app/shared/calendar/calendar-components.module";

// custom shared component 
import { DatepickerModule } from '@app/shared/datepicker/datepicker.module';
import { MattableModule } from '@app/shared/mat-table/mattable.module';
import { SelectFormModule } from '@app/shared/select-form/select-form.module';
import { VideoTabsModule } from '@app/shared/video-tabs/video-tabs.module'

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

// MATERIAL
import {
  MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
  MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
  MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatSidenavModule, MatTabsModule, MatSliderModule, MatSlideToggleModule, MatListModule, MatChipsModule, MatStepperModule, MatProgressSpinnerModule, MatBadgeModule, MatProgressBarModule, MatTooltipModule
} from '@angular/material';
import { MAT_DATE_LOCALE } from '@angular/material';

import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    SmartadminLayoutModule,
    BootstrapModule,
    FileUploadModule
  ],
  declarations: [],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'zh-Hant' }],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,

    UserModule,
    SmartadminLayoutModule,
    BootstrapModule,

    I18nModule,

    UtilsModule,
    PipesModule,

    SmartadminFormsLiteModule,

    SmartProgressbarModule,

    InlineGraphsModule,

    SmartadminWidgetsModule,

    ChatModule,

    StatsModule,

    VoiceControlModule,

    CalendarComponentsModule,

    DatepickerModule,

    VideoTabsModule,

    MattableModule,

    SelectFormModule,

    // MATERIAL
    MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
    MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
    MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule,
    MatSidenavModule, MatTabsModule, MatSliderModule, MatSlideToggleModule, MatListModule, MatChipsModule, MatStepperModule, MatProgressSpinnerModule, MatBadgeModule, MatProgressBarModule, MatTooltipModule,

    // DATEPICKER
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    FileUploadModule
  ]
})
export class SharedModule { }
