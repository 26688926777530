import { DataSource, SelectionModel } from "@angular/cdk/collections";
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from "@angular/core";
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
} from "@angular/material/table";
import { MatPaginator, MatSort } from "@angular/material";
import { TableOptions } from "@app/shared/mat-table/kunyu-table/table-options";
import { ColumnDefs } from "@app/shared/mat-table/kunyu-table/column-defs";
import { Select } from "@app/shared/mat-table/kunyu-table/table-options-enum";
import { noop } from "rxjs/index";

@Component({
  selector: "kunyu-table",
  templateUrl: "./kunyu-table.component.html",
  styleUrls: ["./kunyu-table.component.scss"],
})
export class KunyuTableComponent<T>
  implements OnInit, AfterContentInit, AfterViewInit
{
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  @ViewChild("sort", { static: true }) sort: MatSort;
  @Output() showDetail = new EventEmitter<any>();
  @Input() tableOptions: TableOptions;

  public displayedColumns: string[];
  public selectedName: string = "select";
  public selection;
  private tableFilters = [];
  public tableFilterModel = {};

  async ngOnInit() {
    this.selection = new SelectionModel<ColumnDefs>(
      this.singleOrMultiSelect(),
      []
    );

    // 是否要選擇欄位
    if (this.tableOptions.SingleOrMultiSelect) {
      if (this.tableOptions.SingleOrMultiSelect === Select.Single) {
        this.tableOptions.enableSelectAll = false;
      }

      // 給予selection的databinding
      this.tableOptions.selection = this.selection;

      // 給予selection欄位定義
      this.tableOptions.columnDefs.unshift({
        name: this.selectedName,
      });
    }

    // 是否要篩選欄位
    if (this.tableOptions.enableFiltering) {
      // // console.log(this.tableOptions.enableFiltering);
      this.tableOptions.dataSource.filterPredicate = (
        data: any,
        filtersJson: string
      ) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);

        filters.forEach((filter) => {
          const val = data[filter.id] === null ? "" : data[filter.id];
          matchFilter.push(val.includes(filter.value));
        });
        return matchFilter.every(Boolean);
      };
    }

    if (this.tableOptions.onRegisterApi) {
      this.tableOptions.onRegisterApi(new GridApi(this));
    }

    this.displayedColumns = this.tableOptions.columnDefs.map((col) => col.name);

    this.tableFilterModel = this.tableOptions.columnDefs.reduce((ori, val) => {
      ori[val.name] = "";
      return ori;
    }, {});
  }

  async ngAfterContentInit() {
    // this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    // this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    // this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
  }

  async ngAfterViewInit() {
    // 如果要排序
    if (this.tableOptions.enableSorting) {
      this.tableOptions.dataSource.sort = this.sort;
    }

    // 如果要顯示頁數
    if (this.tableOptions.enablePaginationPage) {
      this.tableOptions.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = "每頁顯示筆數";
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableOptions.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableOptions.dataSource.data.forEach((row) =>
          this.selection.select(row)
        );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(element?: ColumnDefs): string {
    if (!element) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(element) ? "deselect" : "select"} row ${
      element[this.selectedName] + 1
    }`;
  }

  /** Filter condition for each column */
  doFilter(value: string, columnName: string) {
    const index = this.tableFilters.findIndex(
      (tableFilter) => tableFilter.id === columnName
    );
    if (index === -1) {
      this.tableFilters.push({
        id: columnName,
        value: value,
      });
    } else {
      this.tableFilters[index] = {
        id: columnName,
        value: value,
      };
    }

    this.tableOptions.dataSource.filter = JSON.stringify(this.tableFilters);

    if (this.tableOptions.dataSource.paginator) {
      this.tableOptions.dataSource.paginator.firstPage();
    }
  }

  singleOrMultiSelect(): boolean {
    return this.tableOptions.SingleOrMultiSelect === Select.Multiple
      ? true
      : false;
  }

  /**
   *  判斷條件是否要顯示
   * @param pBoolean 預設值
   * @param pEnables 優先權為從小到大
   * @returns {boolean}
   */
  enableHidden(pBoolean: boolean, ...pEnables: boolean[]): boolean {
    for (let i = 0; i < pEnables.length; i++) {
      if (pEnables[i] !== undefined) {
        return pBoolean ? pEnables[i] : !pEnables[i];
      }
    }
    // 如果都undefined
    return !pBoolean;
  }

  showSnapshots(ele) {
    console.log("ele", ele);
    this.showDetail.emit(ele);
  }
}

class GridApi {
  private gridApi: any;

  constructor(gridApi: any) {
    this.gridApi = gridApi;
  }

  setSelected(data: object | object[]) {
    let toggleData = [];
    toggleData = toggleData.concat(data);
    toggleData.forEach((value, index, fullArray) => {
      this.gridApi.selection.toggle(value);
    });
  }

  getSelected() {
    return this.gridApi.selection.selected;
  }
}
