import { Component,Input } from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM',
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'basic-monthpicker',
  templateUrl: './basic-monthpicker.component.html',
  styleUrls: ['./basic-monthpicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
  ],
})
export class BasicMonthpickerComponent {

  @Input() ctrl = new FormControl(moment());
  @Input() label = '請選擇';
  @Input() minDate: Moment;
  @Input() maxDate: Moment;

  chosenYearHandler(normalizedYear: Moment, item) {
    console.log(item);
    const ctrlValue = item.value;
    ctrlValue.year(normalizedYear.year());
    item.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, item) {
    const ctrlValue = item.value;
    ctrlValue.month(normalizedMonth.month());
    item.setValue(ctrlValue);
    datepicker.close();
  }

  // getValueAsJSON(): string {
  //   let out = this.ctrl.value;
  //   return JSON.stringify(out);
  // }

}

