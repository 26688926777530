import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealtimeOneVideoComponent } from './realtime-one-video/realtime-one-video.component';
import { HistoryOneVideoComponent } from './history-one-video/history-one-video.component';

import {
  MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
  MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
  MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatTabsModule, MatNativeDateModule, MatListModule, MatChipsModule
} from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatVideoModule } from 'mat-video';

@NgModule({
  declarations: [RealtimeOneVideoComponent , HistoryOneVideoComponent],
  imports: [
    CommonModule,

    MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
    MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
    MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatTabsModule, MatNativeDateModule, MatListModule, MatChipsModule,

    ReactiveFormsModule,FormsModule,

    MatVideoModule,
  ],
  exports: [RealtimeOneVideoComponent , HistoryOneVideoComponent],
  entryComponents: []
})


export class VideoTabsModule { }
