import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import * as fromUser from '@app/core/store/user';
import { User } from '@app/core/models';

@Component({
  selector: 'history-one-video',
  templateUrl: './history-one-video.component.html',
  styleUrls: ['./history-one-video.component.scss']
})
export class HistoryOneVideoComponent<T> implements OnInit {
  currentUser$: Observable<User>
  obs: BehaviorSubject<any>;
  myWebSocket: SafeResourceUrl;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  @Input() dataSource: MatTableDataSource<T>;
  @Input() paginationPageSizes: Array<number>;
  @Input() styleClass: string;
  @Input() btnStyleClass: string;
  @Input() iconStyleClass: string;
  @Input() styleClassLarge: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private store: Store<fromUser.UserState>) {
    this.currentUser$ = store.select(fromUser.getCurrentUser);
  }

  async ngOnInit() {
    this.changeDetectorRef.detectChanges();
    this.obs = await this.dataSource.connect();

    this.obs.getValue().map((value) => {
      this.loadLiveVideo(value);
    })
  }

  loadLiveVideo(value) {
    // console.log('2021/05/25------------------------------------------value', value);
    const message = {
      cmd: "seek",
      timestamp: value.Timestamp
    };
    // const aa =  new Date(value.Timestamp);
    // console.log('2021/05/25------------------------------------------message', message, 'aa', aa);

    value.WebSocketSubject.next(message);

    value.WebSocketSubject.subscribe(msg => {
      // console.log('2021/05/25------------------------------------------處理WebSocket回應，msg', msg);
      if (msg.data instanceof Blob) {
        let reader = new FileReader(),
          base64data;
        reader.readAsDataURL(msg.data);
        reader.onloadend = (e) => {
          let re = /data:application\/octet-stream;/gi;
          base64data = reader.result;
          base64data = base64data.replace(re, '');
          // // console.log("data=>", base64data);
          value.myWebSocket = this.sanitizer.bypassSecurityTrustUrl('data:image/gif;' + base64data);
          // return msg;
        }
      }

      if (typeof msg.data === "string") {
        value.HistoryTimestamp = JSON.parse(msg.data);
        // // console.log(JSON.parse(msg.data))
        // return JSON.parse(msg.data)
      }

    });
  }

  Play(value) {
    let message = {
      cmd: "play",
      rate: 1 };
    
    value.WebSocketSubject.next(message);

    value.WebSocketSubject.subscribe(msg => {
      if (msg.data instanceof Blob) {
        let reader = new FileReader(),
          base64data;
        reader.readAsDataURL(msg.data);
        reader.onloadend = (e) => {
          let re = /data:application\/octet-stream;/gi;
          base64data = reader.result;
          base64data = base64data.replace(re, '');
          // // console.log("data=>", base64data);
          value.myWebSocket = this.sanitizer.bypassSecurityTrustUrl('data:image/gif;' + base64data);
          // return msg;
        }
        // // console.log(msg);
      }

      if (typeof msg.data === "string") {
        const el = JSON.parse(msg.data);
        if (el.codecId == -1) {
          value.WebSocketSubject.unsubscribe();
        }
        // return JSON.parse(msg.data)
      }
      // // console.log(msg);
    });

  }

  Pause(value) {
    let message = {
      cmd: "pause"
    };
    value.WebSocketSubject.next(message);
  }

  Forward(value , par) {
    let message = {
      cmd: "play",
      rate: par
    };
    
    value.WebSocketSubject.next(message);

    value.WebSocketSubject.subscribe(msg => {
      if (msg.data instanceof Blob) {
        let reader = new FileReader(),
          base64data;
        reader.readAsDataURL(msg.data);
        reader.onloadend = (e) => {
          let re = /data:application\/octet-stream;/gi;
          base64data = reader.result;
          base64data = base64data.replace(re, '');
          // // console.log("data=>", base64data);
          value.myWebSocket = this.sanitizer.bypassSecurityTrustUrl('data:image/gif;' + base64data);
          // return msg;
        }
        // // console.log(msg);
      }

      if (typeof msg.data === "string") {
        const el = JSON.parse(msg.data);
        if (el.codecId == -1) {
          value.WebSocketSubject.unsubscribe();
        }
        // return JSON.parse(msg.data)
      }
      // // console.log(msg);
    });
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '每頁顯示筆數';
  }

  ngOnDestroy() {
    // if (this.dataSource) {
    //   this.dataSource.disconnect();
    // }

    // this.dataSource.data.map((value) => {
    //   value['WebSocketSubject'].unsubscribe();
    //   value['WebSocketSubject'] = undefined;
    // })
  }

}
