
import { Component , Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'basic-datepicker',
  templateUrl: './basic-datepicker.component.html',
  styleUrls: ['./basic-datepicker.component.scss']
})
export class BasicDatepickerComponent {

  @Input() label = '請選擇';
  @Input() required?= false;
  @Input() formControl: FormControl;

  innerValue;

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any) {
    this.innerValue = value;
    this.formControl.setValue(this.innerValue);
  }

  // This needs to be wired to the dateChange event and not dateInput event so that
  // the changed value is only bubbled up when the user changes focus or selects from
  // the popup calendar and not on each key stroke
  onDateChange(event) {
    this.onChange(event.value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // setDisabledState(isDisabled: boolean) {
  //   this.disabled = isDisabled;
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  validate(control: FormControl) {
    const errors = Object.assign({}, this.formControl.errors || {});
    return Object.keys(errors).length && this.formControl.invalid ? errors : null;
  }

  onBlur($event) {
    if ($event.target && $event.target.value && $event.target.value.length === 8 && !isNaN($event.target.value)) {
      const val: String = $event.target.value;
      const month = val.slice(0, 2);
      const day = val.slice(2, 4);
      const year = val.slice(4);
      this.innerValue = new Date(`${month}/${day}/${year}`);
      this.formControl.setValue(this.innerValue);
      this.formControl.updateValueAndValidity();
      this.onChange(this.innerValue);
    }
    if (this.formControl.hasError('matDatepickerParse')) {
      this.formControl.setValue(null);
      this.formControl.updateValueAndValidity();
    }

    this.onTouched();
  }
}
