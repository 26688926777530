import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sa-cga-layout',
    templateUrl: './cga-layout.component.html',
    styles: [],
})
export class CgaLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}