import { UserService } from "@app/core/services/user.service";
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";
import { config } from "@app/core/app.config";
import { CCTV } from "@app/core/models";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class TestService {
  cctvs$ = new BehaviorSubject<CCTV>(null);
  cctvswithoutga$ = new BehaviorSubject<CCTV>(null);

  ipAddress$ = "";

  constructor(private http: HttpClient) { }

  getIP() {
    // return this.http
    //   .get("http://api.ipify.org/?format=json")
    //   .subscribe((res: any) => {
    //     this.ipAddress$ = res.ip;
    //   });
  }

  public GetCCTVInfo(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.cctvInfoUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => {
        this.cctvs$.next(data);
        return data || data.data;
      }),
      catchError(this.handleError)
    );
  }

  public GetCCTVInfoWithoutGA(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.cctvInfoUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => {
        const tmp = data.filter((el) => {
          return !el.CCTVID.includes("GA");
        });
        this.cctvswithoutga$.next(tmp);
        return data || data.data;
      }),
      catchError(this.handleError)
    );
  }

  public GetHistoryByCar(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.historyByCarUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  }

  public GetHistoryByCCTV(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.historyByCCTVUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          // // console.log(data);
          return data;
          // return data || data.data;
        }),
        catchError(this.handleError)
      );
  }

  public GetCCTVImage(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.cctvImg, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public CreateHistoryLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.crtHVLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public GetHistoryLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.getHVLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public CreateRealtimeLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.crtRVLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public GetRealtimeLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.getRVLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public GetCarplateLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.getCRLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public GetUserStatisticLog(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.getUserLogUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public GetSnapShot(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http.post<any>(config.SnapShotUrl, dataSrc, httpOptions).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  public ExportVideo(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.ExportVideoUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public GetMyFavorite(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.getMyFavoriteUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public CreateMyFavorite(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.crtMyFavoriteUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public DeleteMyFavorite(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.delMyFavoriteUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public BothAnalysis(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.bothAnalysisUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }
  public IntersectionAnalysis(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.intersectionAnalysisUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }
  public UnionAnalysis(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.unionAnalysisUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public CreateGantryArea(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.crtGantryAreaUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public UpdateGantryArea(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.updGantryAreaUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  public GetDisconnectedReport(dataSrc: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set("IpAddress", this.ipAddress$);
    return this.http
      .post<any>(config.getDisconnectedReportUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => data.data || data),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error);
  }
}
