import { Pipe, PipeTransform } from '@angular/core';
import { Area } from '@app/core/models';

@Pipe({
  name: 'sysCodeAreaFilter'
})
export class SysCodeAreaPipe implements PipeTransform {

  transform(value: string, exponent: Area[]): string {
    // console.log(value , exponent)
    let data: Area[] = exponent.filter((item: Area) => item.Area == value);
    return data.length > 0 ? data[0].AreaName : "";
  }
}
