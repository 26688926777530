import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";
import { config } from '@app/core/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CheckService {
  ipAddress$ = '';
  constructor(private http: HttpClient) { }

  getIP() {
    // return this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress$ = res.ip
    // });
  }

  public GetCCTVCheckList(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getCheckUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  }

  public GetCCTVDailyCheck(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getDailyCheckUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public CreateCCTVDailyCheck(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.crtDailyCheckUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetRepairApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getRepairApply, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  }

  public CreateRepairApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.crtRepairApply, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public UpdateRepairApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updRepairApply, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetHistoryStatus(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getHistoryStatusUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetEquipmentStatus(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getEquipmentStatusUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetHistorySnapshot(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getHistorySnapshotUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };


  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error)
  }
}
