import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs/index";
import { config } from '@app/core/app.config';
import { throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from "rxjs/internal/operators";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class BillboardService {
  ipAddress$ = '';
  constructor(private http: HttpClient) { }

  getIP() {
    // return this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress$ = res.ip
    // });
  }
  public GetBillboard(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getBBUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => { return data || data.data }),
        catchError(this.handleError)
      );
  };

  public CreateBillboard(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.crtBBUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  public UpdateBillboard(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updBBUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  public DeleteBillboard(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.delBBUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  public DeleteBillboardFile(fileIdObj: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.delBBFileUrl, fileIdObj, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  // public DeleteMSSQLData(dataSrc: any): Observable<any> {
  //   return this.http.delete<any>(config.apiUrl, { params: dataSrc })
  //     .pipe(
  //       delay(100),
  //       map((data: any) => (data || data.data)),
  //       catchError(this.handleError)
  //     );
  // };

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error)
  }
}
