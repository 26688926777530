import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";
import { config } from '@app/core/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  ipAddress$ = '';
  constructor(private http: HttpClient) { }

  getIP() {
    // return this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress$ = res.ip
    // });
  }
  public UpdateMaintainCheck(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updMaintainCheckUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetMaintainCheckList(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getMaintainCheckUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public UpdateMonthlyRentDeduct(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updMonthlyRentDeductUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetMonthlyRentDeductList(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getMonthlyRentDeductUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetMonthlyRentDeductReport(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getMonthlyDeductReportUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetMaintainReport(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getMaintainReportUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetRepairReport(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getRepairReportUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public UpdateRentDeductApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updRentDeductApplyUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };

  public GetChargeReport(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getChargeReportUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data.data || data)),
        catchError(this.handleError)
      )
  };



  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error)
  }
}


