import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";
import { config } from '@app/core/app.config';
import { Department, Area, ErrorOption, RepairStatus, CarColor, CarType, ReviewStatus, CarPlateLen, Intersection, CCTVInfoField } from '@app/core/models';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class SyscodeService {
  ipAddress$ = '';

  department$ = new BehaviorSubject<Array<Department>>(null);
  area$ = new BehaviorSubject<Area>(null);
  areaCCTV$ = new BehaviorSubject<any>(null);
  ErrorOption$ = new BehaviorSubject<ErrorOption>(null);
  RepairStatus$ = new BehaviorSubject<RepairStatus>(null);
  CarColor$ = new BehaviorSubject<CarColor>(null);
  CarType$ = new BehaviorSubject<CarType>(null);
  ReviewStatus$ = new BehaviorSubject<ReviewStatus>(null);
  CarPlateLen$ = new BehaviorSubject<CarPlateLen>(null);
  Intersection$ = new BehaviorSubject<Intersection>(null);
  CCTVInfoField$ = new BehaviorSubject<CCTVInfoField>(null);
  RentDeductItem$ = new BehaviorSubject<CCTVInfoField>(null);

  constructor(private http: HttpClient) { }

  getSyscodeCode(opts: string[]): Observable<any> {
    return this.http.post(config.SysCodeUrl, opts);
  }

  GetArea(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.GetAreaUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.area$.next(data);
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  }
  GetAreaCCTV(): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.GetAreaCCTVUrl, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.areaCCTV$.next(data);
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  }

  getIP() {
    // return this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress$ = res.ip
    // });
  }

  public GetDept(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.GetDeptUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          data.map((value) => {
            value.UserDept = value.DeptID;
            return data
          });
          this.department$.next(data);
        }),
        catchError(this.handleError)
      );
  };


  public GetAreaLocal(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.GetAreaUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  };

  public GetErrorOption(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "CCTVStatus" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.ErrorOption$.next(data);
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  };

  public GetCarColor(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "CarColor" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.CarColor$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetCarType(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "CarType" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.CarType$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetRepairStatus(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "RepairStatus" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.RepairStatus$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetReviewStatus(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "ReviewStatus" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.ReviewStatus$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetCarPlateLen(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "CarPlateLen" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.CarPlateLen$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetIntersection(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "Intersection" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.Intersection$.next(data);
        }),
        catchError(this.handleError)
      );
  };

  public GetCCTVInfoField(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "CCTVInfoField" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.CCTVInfoField$.next(data);
          return data || data.data;
        }),
        catchError(this.handleError)
      );
  };

  public GetRentDeductItem(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.SysCodeUrl, { TYPE: "RentDeductItem" }, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => {
          this.RentDeductItem$.next(data);
        }),
        catchError(this.handleError)
      );
  };



  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error)
  }
}
