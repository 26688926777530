// import { Apply } from './../../../features/monitor/monitor';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs/Rx";
import { Store } from "@ngrx/store";
import * as fromUser from '@app/core/store/user';
import { User } from '@app/core/models';

@Component({
  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {
  currentUser$: Observable<User>;
  currentUser: User;

  Notice: boolean = true;
  Monitor: boolean = true;
  LicensePlate: boolean = true;
  Verify: boolean = true;
  Inspect: boolean = true;
  Audit: boolean = true;
  Report: boolean = true;
  Administrator: boolean = true;

  // 影像監看服務分頁權限
  Maps: boolean = true;
  RealtimeVideos: boolean = true;
  HistoryVideos: boolean = true;
  Favoriate: boolean = true;

  // 車牌辨識分頁權限
  Cctv: boolean = true;
  CarplateDirective: boolean = true;
  AreaGroup: boolean = true;

  // 案件管理分頁權限
  Progress: boolean = true;
  Apply: boolean = true;
  Review: boolean = true;

  // 稽核檢查分頁權限
  DailyCheck: boolean = true;
  CheckRecord: boolean = true;
  EquipmentMonitor: boolean = true;
  Reliability: boolean = true;
  HistoryImg: boolean = true;
  Maintenance: boolean = true;
  MaintenReport: boolean = true;
  ReportExport: boolean = true;

  // 統計報表分頁權限
  UserStatistic: boolean = true;
  VideoStatistic: boolean = true;
  CarplateStatistic: boolean = true;

  // 計費查核分頁權限
  AccountDetails: boolean = true;
  RepairReport: boolean = true;
  MaintainReport: boolean = true;
  HistoryReport: boolean = true;
  MaintainCheck: boolean = true;
  OtherReport: boolean = true;

  MonitorRecord: boolean = true;
  HistoryRecord: boolean = true;
  CarplateRecord: boolean = true;
  Bulletin: boolean = true;
  GroupManage: boolean = true;
  Authority: boolean = true;

  constructor(private store: Store<fromUser.UserState>) {
    this.currentUser$ = store.select(fromUser.getCurrentUser);
  }

  async ngOnInit() {
    this.currentUser = await this.currentUser$.take(1).toPromise<User>();

    if (this.currentUser.Permission) {
      // 公告顯示
      this.Notice = this.currentUser.Permission.includes('notice');
      // 影像監看服務顯示
      this.Monitor = this.currentUser.Permission.includes('maps')
        || this.currentUser.Permission.includes('realtime-videos')
        || this.currentUser.Permission.includes('history-videos')
        || this.currentUser.Permission.includes('favoriate');

      this.Maps = this.currentUser.Permission.includes("maps");
      this.RealtimeVideos = this.currentUser.Permission.includes("realtime-videos");
      this.HistoryVideos = this.currentUser.Permission.includes("history-videos");
      this.Favoriate = this.currentUser.Permission.includes("favoriate");

      // 車牌辨識功能顯示
      this.LicensePlate = this.currentUser.Permission.includes('cctv')
        || this.currentUser.Permission.includes('carplate-directive')
        || this.currentUser.Permission.includes('area-group');

      this.Cctv = this.currentUser.Permission.includes("cctv")
      this.CarplateDirective = this.currentUser.Permission.includes("carplate-directive")
      this.AreaGroup = this.currentUser.Permission.includes("area-group")

      // 案件管理顯示
      this.Verify = this.currentUser.Permission.includes('progress')
        || this.currentUser.Permission.includes('apply-list')
        || this.currentUser.Permission.includes('review-list');

      this.Progress = this.currentUser.Permission.includes('progress');
      this.Apply = this.currentUser.Permission.includes('apply-list');
      this.Review = this.currentUser.Permission.includes('review-list');
      // 稽核檢查顯示
      this.Inspect = this.currentUser.Permission.includes("daily-check")
        || this.currentUser.Permission.includes("check-record")
        || this.currentUser.Permission.includes("equipment-monitor")
        || this.currentUser.Permission.includes("reliability")
        || this.currentUser.Permission.includes("history-img")
        || this.currentUser.Permission.includes("maintenance")
        || this.currentUser.Permission.includes("mainten-report")
        || this.currentUser.Permission.includes("report-export");

      this.DailyCheck = this.currentUser.Permission.includes("daily-check");
      this.CheckRecord = this.currentUser.Permission.includes("check-record");
      this.EquipmentMonitor = this.currentUser.Permission.includes("equipment-monitor");
      this.Reliability = this.currentUser.Permission.includes("reliability");
      this.HistoryImg = this.currentUser.Permission.includes("history-img");
      this.Maintenance = this.currentUser.Permission.includes("maintenance");
      this.MaintenReport = this.currentUser.Permission.includes("mainten-report");
      this.ReportExport = this.currentUser.Permission.includes("report-export");

      // 計費查核顯示
      this.Audit = this.currentUser.Permission.includes("account-details")
        || this.currentUser.Permission.includes("repair-report")
        || this.currentUser.Permission.includes("maintain-report")
        || this.currentUser.Permission.includes("history-report")
        || this.currentUser.Permission.includes("maintain-check")
        || this.currentUser.Permission.includes("other-report");

      this.AccountDetails = this.currentUser.Permission.includes("account-details");
      this.RepairReport = this.currentUser.Permission.includes("repair-report");
      this.MaintainReport = this.currentUser.Permission.includes("maintain-report");
      this.HistoryReport = this.currentUser.Permission.includes("history-report");
      this.MaintainCheck = this.currentUser.Permission.includes("maintain-check");
      this.OtherReport = this.currentUser.Permission.includes("other-report");
      // 統計報表顯示
      this.Report = this.currentUser.Permission.includes("user-statistic")
        || this.currentUser.Permission.includes("video-statistic")
        || this.currentUser.Permission.includes("carplate-statistic");

      this.UserStatistic = this.currentUser.Permission.includes("user-statistic")
      this.VideoStatistic = this.currentUser.Permission.includes("video-statistic")
      this.CarplateStatistic = this.currentUser.Permission.includes("carplate-statistic");
      // 系統管理顯示
      this.Administrator = this.currentUser.Permission.includes("monitor-record")
        || this.currentUser.Permission.includes("history-record")
        || this.currentUser.Permission.includes("carplate-record")
        || this.currentUser.Permission.includes("bulletin")
        || this.currentUser.Permission.includes("group-manage")
        || this.currentUser.Permission.includes("authority");

      this.MonitorRecord = this.currentUser.Permission.includes("monitor-record");
      this.HistoryRecord = this.currentUser.Permission.includes("history-record");
      this.CarplateRecord = this.currentUser.Permission.includes("carplate-record");
      this.Bulletin = this.currentUser.Permission.includes("bulletin");
      this.GroupManage = this.currentUser.Permission.includes("group-manage");
      this.Authority = this.currentUser.Permission.includes("authority");
    } else {
      this.Notice = true;
      this.Monitor = false;
      this.LicensePlate = false;
      this.Verify = false;
      this.Inspect = false;
      this.Audit = false;
      this.Report = false;
      this.Administrator = false;

      // 影像監看服務分頁權限
      this.Maps = false;
      this.RealtimeVideos = false;
      this.HistoryVideos = false;
      this.Favoriate = false;

      // 車牌辨識分頁權限
      this.Cctv = false;
      this.CarplateDirective = false;
      this.AreaGroup = false;

      // 案件管理分頁權限
      this.Progress = false;
      this.Apply = false;
      this.Review = false;

      // 稽核檢查分頁權限
      this.DailyCheck = false;
      this.CheckRecord = false;
      this.EquipmentMonitor = false;
      this.Reliability = false;
      this.HistoryImg = false;
      this.Maintenance = false;
      this.MaintenReport = false;
      this.ReportExport = false;

      // 統計報表分頁權限
      this.UserStatistic = false;
      this.VideoStatistic = false;
      this.CarplateStatistic = false;

      // 計費查核分頁權限
      this.AccountDetails = false;
      this.RepairReport = false;
      this.MaintainReport = false;
      this.HistoryReport = false;
      this.MaintainCheck = false;
      this.OtherReport = false;

      this.MonitorRecord = false;
      this.HistoryRecord = false;
      this.CarplateRecord = false;
      this.Bulletin = false;
      this.GroupManage = false;
      this.Authority = false;
    }

  }
}
