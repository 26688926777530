import { Component, OnInit, Input } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM',
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'monthpicker-minmax',
  templateUrl: './monthpicker-minmax.component.html',
  styleUrls: ['./monthpicker-minmax.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
  ]
})
export class MonthpickerMinmaxComponent {

  @Input() ctrlStart = new FormControl(moment());
  @Input() ctrlEnd = new FormControl(moment());
  minDate: Moment;

  constructor() { }

  chosenYearHandler(normalizedYear: Moment , ctrl) {
    const ctrlValue = ctrl.value;
    ctrlValue.year(normalizedYear.year());
    ctrl.setValue(ctrlValue);
  }

  chosenFirstMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.ctrlStart.value;
    this.minDate = ctrlValue;
    ctrlValue.month(normalizedMonth.month());
    this.ctrlStart.setValue(ctrlValue);
    datepicker.close();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, ctrl) {
    const ctrlValue = ctrl.value;
    ctrlValue.month(normalizedMonth.month());
    ctrl.setValue(ctrlValue);
    datepicker.close();
  }


}
