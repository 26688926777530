import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { SysCodePipe } from './syscode.pipe';
import { SysCodeAreaPipe } from './syscodeArea.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, SysCodePipe, SysCodeAreaPipe],
  exports: [FieldFilterPipe, MomentPipe, SysCodePipe, SysCodeAreaPipe]
})
export class PipesModule { }
