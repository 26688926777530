import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

@Component({
  selector: 'datepicker-minmax',
  templateUrl: './datepicker-minmax.component.html',
  styleUrls: ['./datepicker-minmax.component.scss'],
})
export class DatepickerMinmaxComponent {
  @Input() ctrlStart = new FormControl(moment());
  @Input() ctrlEnd = new FormControl(moment());
  @Input() minStartDate: Date;
  @Input() maxStartDate: Date;
  @Input() dateLimit: number;
  @Input() label: string;

  @Input() sameday: boolean;

  minEndDate: Date;
  maxEndDate: Date;

  constructor() {}

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.sameday) {
      this.ctrlEnd.setValue(event.value);
      this.minEndDate = event.value;
      if (this.dateLimit) {
        this.maxEndDate = this.AddDays(event.value, this.dateLimit);
      } else {
        this.maxEndDate = this.maxStartDate;
      }
    } else {
      this.ctrlEnd.setValue('');
      this.minEndDate = event.value;
      if (this.dateLimit) {
        this.maxEndDate = this.AddDays(event.value, this.dateLimit);
      } else {
        this.maxEndDate = this.maxStartDate;
      }
    }
  }

  AddDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }
}
