import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    // const fileName = 'test.xlsx';

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arr);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'test');
    // XLSX.utils.book_append_sheet(wb, ws, 'test2');

    // XLSX.writeFile(wb, fileName);

    const header = Object.keys(json[0]); // columns name

    let wscols = [];
    header.map(el => (wscols.push({ wch: el.length + 20 })))

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet["!cols"] = wscols;
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { '明細': worksheet }, SheetNames: ['明細'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportMultipleAsExcelFile(json: any[], json2: any[], excelFileName: string): void {

    // const fileName = 'test.xlsx';

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arr);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'test');
    // XLSX.utils.book_append_sheet(wb, ws, 'test2');

    // XLSX.writeFile(wb, fileName);

    const header = Object.keys(json[0]); // columns name
    const header2 = Object.keys(json2[0]); // columns name

    let wscols = [];
    let wscols2 = [];
    header.map(el => (wscols.push({ wch: el.length + 20 })))
    header2.map(el => (wscols2.push({ wch: el.length + 20 })))

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet["!cols"] = wscols;
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    worksheet2["!cols"] = wscols2;
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '總表');
    XLSX.utils.book_append_sheet(workbook, worksheet2, '明細');
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
