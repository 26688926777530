import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";
import { config } from '@app/core/app.config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  ipAddress$ = '';

  constructor(private http: HttpClient) { }

  getIP() {
    // return this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   this.ipAddress$ = res.ip
    // });
  }

  public GetHistoryVideoApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.getHVAUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => { return data || data.data }),
        catchError(this.handleError)
      );
  };

  public CreateHistoryVideoApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.creatHVUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  public UpdateHistoryVideoApply(dataSrc: any): Observable<any> {
    httpOptions.headers =
      httpOptions.headers.set('IpAddress', this.ipAddress$);
    return this.http.post<any>(config.updateHVAUrl, dataSrc, httpOptions)
      .pipe(
        delay(100),
        map((data: any) => (data || data.data)),
        catchError(this.handleError)
      );
  };

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    // let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // console.error(errMsg); // log to console instead

    return throwError(error)
  }
}

